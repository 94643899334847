<template>
  <div class="outer-page">
    <div id="container" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    currentMapInfo: Object,
  },
  data() {
    return {
      map: null,
      mapList: [],
      //养殖场坐标点
      farmPoint: new Map(),
      //养殖场基地
      farmBase: new Map(),
      //设备点
      farmSite: new Map(),
    };
  },
  methods: {
    //初始化地图
    initMap() {
      this.map = new BMapGL.Map("container", {
        //卫星地图
        mapType: BMAP_EARTH_MAP,
      });
      //创建Map实例
      this.map.centerAndZoom(new BMapGL.Point(105.59442, 29.40488), 11); //初始化地图,设置中心点坐标和地图级别
      this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    },
    //加载地图方法
    mapLoad() {
      this.addRCMap();
      this.getPoint();
      let _self = this;
      this.map.addEventListener("zoomend", function (e) {
        _self.zoomChange();
      });
    },
    //养殖场列表
    getList() {
      this.$post(this.$api.MAP.MAP_CFG_INFO, {
        farmType: 2,
      }).then((res) => {
        this.mapList = res.list;
        this.mapLoad();
      });
    },
    //养殖场图标显示
    getPoint() {
      if (!this.mapList || this.mapList.length == 0) return;
      for (let i = 0; i < this.mapList.length; i++) {
        let mapList = this.mapList[i];
        //设置经纬度
        let pt = new BMapGL.Point(
          mapList.mapCenterLocation.split(",")[0],
          mapList.mapCenterLocation.split(",")[1]
        );
        let key = mapList.sourceId;
        //文本标注--点标记
        this.farmPoint.set(
          key,
          new BMapGL.Label(
            `<img src="${
              mapList.showPointCoverings
                ? mapList.servicePath + mapList.showPointCoverings
                : "imgs/location.png"
            }" style="width: 24px;height: 24px;cursor: pointer" />`,
            {
              position: pt,
            }
          )
        );
        //点标记样式
        this.farmPoint.get(key).setStyle({
          background: "none",
          border: "none",
        });
        this.map.addOverlay(this.farmPoint.get(key));
        //点标记事件
        let textLabel = null;
        let _self = this;
        this.farmPoint.get(key).addEventListener("mouseover", function () {
          //创建文本标注对象
          textLabel = new BMapGL.Label(mapList.mapName, {
            //指定文本标注所在的地理位置
            position: pt,
            //文本偏移量
            offset: new BMapGL.Size(-60, -25),
          });
          textLabel.setStyle({
            background: "none",
            border: "none",
            color: "#000",
            textAlign: "center",
            width: "150px",
            fontSize: "16px",
          });
          _self.map.addOverlay(textLabel);
        });
        //移除文本标记
        this.farmPoint.get(key).addEventListener("mouseout", function () {
          _self.map.removeOverlay(textLabel);
          textLabel = null;
        });
        this.farmPoint.get(key).addEventListener("click", function () {
          //点击把当前条数据传出去
          _self.$emit("getMapInfo", mapList);
        });
      }
    },
    //获取地图边界
    addRCMap() {
      //定义行政区域的边界
      let boundary = new BMapGL.Boundary();
      boundary.get("重庆市荣昌区", (e) => {
        if (!e) return;
        //循环获取边界经纬度
        for (let i = 0; i < e.boundaries.length; i++) {
          let point = e.boundaries[i];
          let points = point.split(";").map((item) => {
            //设置点坐标
            return new BMapGL.Point(item.split(",")[0], item.split(",")[1]);
          });
          //多边形覆盖
          let polygon = new BMapGL.Polygon(points, {
            strokeColor: "#08dddd",
            fillColor: "#08dddd",
            strokeWeight: 2,
            strokeOpacity: 1,
            fillOpacity: 0.5,
            strokeStyle: "solid",
          });
          this.map.addOverlay(polygon);
        }
      });
    },
    //养殖场下基地
    getFarmBase() {
      return new Promise((resolve) => {
        this.$post(this.$api.BASE_MANAGE.VIEW, {
          id: this.currentMapInfo.sourceId,
        }).then((res) => {
          if (!res.mapPosition) return resolve();
          let mapPosition = JSON.parse(res.mapPosition);
          for (let i = 0; i < mapPosition.length; i++) {
            let temp = mapPosition[i];
            if (temp.type == "polygon") {
              let path = JSON.parse(temp.options.path);
              let points = path.map((item) => {
                return new BMapGL.Point(item.lng, item.lat);
              });
              this.farmBase.set(
                res.id + "_farm_" + i,
                new BMapGL.Polygon(points, {
                  fillColor: temp.options.fillColor,
                  fillOpacity: +temp.options.fillOpacity,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: +temp.options.strokeOpacity,
                  strokeWeight: temp.options.strokeWeight,
                })
              );
              this.map.addOverlay(this.farmBase.get(res.id + "_farm_" + i));
            }
          }
          resolve();
        });
      });
    },
    //养殖场下的区域
    getFarmArea() {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCTION_AREA.LIST, {
          farmId: this.currentMapInfo.sourceId,
        }).then((res) => {
          if (res.list.length == 0) return resolve();
          for (let i = 0; i < res.list.length; i++) {
            let area = res.list[i];
            if (!area.mapPosition) continue;
            let mapPosition = JSON.parse(area.mapPosition);
            for (let j = 0; j < mapPosition.length; j++) {
              let temp = mapPosition[j];
              if (temp.type == "polygon") {
                let path = JSON.parse(temp.options.path);
                let points = path.map((item) => {
                  return new BMapGL.Point(item.lng, item.lat);
                });
                this.farmBase.set(
                  area.id + "_area_" + j,
                  new BMapGL.Polygon(points, {
                    fillColor: temp.options.fillColor,
                    fillOpacity: +temp.options.fillOpacity,
                    strokeColor: temp.options.strokeColor,
                    strokeOpacity: +temp.options.strokeOpacity,
                    strokeWeight: temp.options.strokeWeight,
                  })
                );
                this.map.addOverlay(this.farmBase.get(area.id + "_area_" + j));
              }
            }
          }
          resolve();
        });
      });
    },
    //养殖场下的设备点
    getFarmSite() {
      return new Promise((resolve) => {
        this.$store
          .dispatch("getSites", {
            farmId: this.currentMapInfo.sourceId,
            companyNo: this.currentMapInfo.companyNo,
          })
          .then((res) => {
            if (res.length == 0) return resolve();
            for (let i = 0; i < res.length; i++) {
              let site = res[i];
              let center = site.thirdLongLat;
              if (!center) continue;
              let point = new BMapGL.Point(
                center.split(",")[0],
                center.split(",")[1]
              );
              let key = site.guid + "_site_" + i;
              this.farmSite.set(
                key,
                new BMapGL.Label(
                  `<img src="${
                    site.logo
                      ? site.servicePath + site.logo
                      : "imgs/location.png"
                  }"style="width: 24px;height: 24px;cursor: pointer" />`,
                  {
                    position: point,
                  }
                )
              );
              this.farmSite.get(key).setStyle({
                background: "none",
                border: "none",
              });
              this.map.addOverlay(this.farmSite.get(key));
              let textLabel = null;
              let _this = this;
              this.farmSite.get(key).addEventListener("mouseover", function () {
                textLabel = new BMapGL.Label(site.name, {
                  position: point,
                  offset: new BMapGL.Size(-60, -25),
                });
                textLabel.setStyle({
                  background: "none",
                  border: "none",
                  color: "#000",
                  textAlign: "center",
                  width: "150px",
                  fontSize: "16px",
                });
                _this.map.addOverlay(textLabel);
              });
              this.farmSite.get(key).addEventListener("mouseout", function () {
                _this.map.removeOverlay(textLabel);
                textLabel = null;
              });
              this.farmSite.get(key).addEventListener("click", function () {
                _this.$emit("site-click", site);
              });
            }
          });
      });
    },
    //跳转养殖场
    jumpMap() {
      if (!this.currentMapInfo) return;
      this.getFarmBase();
      this.getFarmArea();
      this.getFarmSite();
      let center = this.currentMapInfo.mapCenterLocation.split(",");
      this.map.panTo(new BMapGL.Point(center[0], center[1]));
      this.map.setZoom(16, {
        zoomCenter: new BMapGL.Point(center[0], center[1]),
      });
    },
    //地图层级变化
    zoomChange() {
      let zoom = this.map.getZoom();
      this.$emit("zoom-change", zoom);
      if (zoom > 15) {
        for (let value of this.farmPoint.values()) {
          value.hide();
        }
        for (let value of this.farmBase.values()) {
          value.show();
        }
        for (let value of this.farmSite.values()) {
          value.show();
        }
      } else {
        for (let value of this.farmPoint.values()) {
          value.show();
        }
        for (let value of this.farmBase.values()) {
          value.hide();
        }
        for (let value of this.farmSite.values()) {
          value.hide();
        }
      }
    },
  },
  mounted() {
    this.getList();
    this.initMap();
  },
  watch: {
    currentMapInfo() {
      this.jumpMap();
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>